import { request } from "./verb-services";

//get backend data types
export const getTypes = (payload, callBack, errorCallBack) => {
  return (dispatch) => {
    return request("component-types", "get", null, true)
      .then(async ({ data }) => {
        // callBack(data?.data);
        return data?.data;
      })
      .catch(function (error) {
        errorCallBack(error?.response?.data?.message);
      });
  };
};

//create backend data types
export const createTypes = (payload, callBack, errorCallBack) => {
  return (dispatch) => {
    return request("component-types", "post", payload, true)
      .then(async ({ data }) => {
        // callBack(data?.data);
        return data?.data;
      })
      .catch(function (error) {
        errorCallBack(error?.response?.data?.message);
      });
  };
};

//create attribute
export const createAttributes = (payload, callBack, errorCallBack) => {
  return (dispatch) => {
    return request("components", "post", payload, true)
      .then(async ({ data }) => {
        // callBack(data?.data);
        return data?.data;
      })
      .catch(function (error) {
        errorCallBack(error?.response?.data?.message);
      });
  };
};

//get all attributes with category and sub category id to update attributes
export const getAttributes = (catid, subcatid, callBack, errorCallBack) => {
  return (dispatch) => {
    return request(`getSingleComponent/${catid}/${subcatid}`, "get", null, true)
      .then(async ({ data }) => {
        // callBack(data?.data);
        return data?.data;
      })
      .catch(function (error) {
        errorCallBack(error?.response?.data?.message);
      });
  };
};

//delete attribute
export const deleteAttributes = (attributeId, callBack, errorCallBack) => {
  return (dispatch) => {
    return request(`components/${attributeId}`, "delete", null, true)
      .then(async ({ data }) => {
        // callBack(data?.data);
        callBack();
        return data;
      })
      .catch(function (error) {
        errorCallBack(error?.response?.data?.message);
      });
  };
};

//get all attributes with category and sub category id to create a product
export const getAttributes_Cat_SubCat = (
  catid,
  subcatid,
  callBack,
  errorCallBack
) => {
  return (dispatch) => {
    return request(
      `getSingleComponent-null/${catid}/${subcatid}`,
      "get",
      null,
      true
    )
      .then(async ({ data }) => {
        // callBack(data?.data);
        return data?.data;
      })
      .catch(function (error) {
        // errorCallBack(error?.response?.data?.message);
      });
  };
};

//create product w.r.t category and sub category
export const createProduct = (payload, callBack, errorCallBack) => {
  return (dispatch) => {
    return request("components-values", "post", payload, true)
      .then(async ({ data }) => {
        return data?.data;
      })
      .catch(function (error) {
        errorCallBack(error?.response?.data?.message);
      });
  };
};

//add product Images
export const addProductImages = (payload, callBack, errorCallBack) => {
  return (dispatch) => {
    return request("product-images", "post", payload, true)
      .then(async ({ data }) => {
        return data?.data;
      })
      .catch(function (error) {
        // errorCallBack(error?.response?.data?.message);
      });
  };
};

//add product Videos
export const addProductVideos = (payload, callBack, errorCallBack) => {
  return (dispatch) => {
    return request("product/video", "post", payload, true)
      .then(async ({ data }) => {
        return data?.data;
      })
      .catch(function (error) {
        // errorCallBack(error?.response?.data?.message);
      });
  };
};

//Delete product Video
export const DeleteProductVideos = (uuid, callBack, errorCallBack) => {
  return (dispatch) => {
    return request(`product/video/remove/${uuid}`, "delete", null, true)
      .then(async ({ data }) => {
        return data?.data;
      })
      .catch(function (error) {
        // errorCallBack(error?.response?.data?.message);
      });
  };
};

//Delete product Video
export const getProductVideos = (uuid, callBack, errorCallBack) => {
  return (dispatch) => {
    return request(`product/video/${uuid}`, "get", null, true)
      .then(async ({ data }) => {
        return data?.data;
      })
      .catch(function (error) {
        // errorCallBack(error?.response?.data?.message);
      });
  };
};

//update product Images
export const updateProductImages = (payload, callBack, errorCallBack) => {
  return (dispatch) => {
    return request("update/product-images", "post", payload, true)
      .then(async ({ data }) => {
        return data?.data;
      })
      .catch(function (error) {
        errorCallBack(error?.response?.data?.message);
      });
  };
};

//get All products
export const getAllProducts = (callBack, errorCallBack) => {
  return (dispatch) => {
    return request("components-values", "get", null, true)
      .then(async ({ data }) => {
        return data?.data;
      })
      .catch(function (error) {
        // errorCallBack(error?.response?.data?.message);
      });
  };
};

// get Products w.r.t user and/or single product
export const getProduct = (userId, productId, callBack, errorCallBack) => {
  return (dispatch) => {
    return request(
      `componentsValues/detail/${userId}/${productId}`,
      "get",
      null,
      true
    )
      .then(async ({ data }) => {
        return data?.data;
      })
      .catch(function (error) {
        errorCallBack(error?.response?.data?.message);
      });
  };
};

// get single product
export const getSingleProductDetails = (productId, callBack, errorCallBack) => {
  return (dispatch) => {
    return request(`componentsValues/single/${productId}`, "get", null, true)
      .then(async ({ data }) => {
        return data?.data;
      })
      .catch(function (error) {
        errorCallBack(error?.response?.data?.message);
      });
  };
};

export const getProductDescription = (uuid, callBack, errorCallBack) => {
  return (dispatch) => {
    return request(`componentsValues/description/${uuid}`, "get", null, true)
      .then(async ({ data }) => {
        return data?.data;
      })
      .catch(function (error) {
        // ("compare product error ->", error);
        // errorCallBack(error?.response?.data?.message);
      });
  };
};

//get component values by category id
export const getProductsByCategory = (payload, callBack, errorCallBack) => {
  return (dispatch) => {
    return request("componentValues/category", "post", payload, true)
      .then(async ({ data }) => {
        return data?.data;
      })
      .catch(function (error) {
        console.log("error", error?.response?.data?.message);
        // errorCallBack(error?.response?.data?.message);
      });
  };
};

// get compare products
export const compareBetweenProducts = (payload, callBack, errorCallBack) => {
  return (dispatch) => {
    return request(`product/compare-product`, "post", payload, true)
      .then(async ({ data }) => {
        return data?.data;
      })
      .catch(function (error) {
        // errorCallBack(error?.response?.data?.message);
      });
  };
};

// get compare products
export const changeProductStatus = (payload, callBack, errorCallBack) => {
  return (dispatch) => {
    return request(`admin/product/status/change`, "post", payload, true)
      .then(async ({ data }) => {
        return data?.data;
      })
      .catch(function (error) {
        // errorCallBack(error?.response?.data?.message);
      });
  };
};

export const deleteProduct = (productId, callBack, errorCallBack) => {
  return (dispatch) => {
    return request(`componentsValues/${productId}`, "delete", null, true)
      .then(async ({ data }) => {
        callBack();
        return data?.data;
      })
      .catch(function (error) {
        errorCallBack(error?.response?.data?.message);
      });
  };
};

//get all products for shop page
export const getAllShopProducts = (callBack, errorCallBack) => {
  return (dispatch) => {
    return request("all-products", "get", null, true)
      .then(async ({ data }) => {
        return data?.data;
      })
      .catch(function (error) {
        // ("compare product error ->", error);
        // errorCallBack(error?.response?.data?.message);
      });
  };
};

//get Product Report for specific product
export const getProductReport = (productId, callBack, errorCallBack) => {
  return (dispatch) => {
    return request(`product-events/${productId}`, "get", null, true)
      .then(async ({ data }) => {
        return data?.data;
      })
      .catch(function (error) {
        // ("compare product error ->", error);
        // errorCallBack(error?.response?.data?.message);
      });
  };
};
