import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { lazy, Suspense } from "react";
import { Box, Typography } from "@mui/material";
import { ThreeCircles } from "react-loader-spinner";
import logo from "./public/loginSignupAssets/Logofastfx.png";
// import logo from "./public/loginSignupAssets/Fastfx_MainImage.jpg";
// import ApplicationRoutes from "./routes/ApplicationRoutes";

const ApplicationRoutes = lazy(() => import("./routes/ApplicationRoutes"));

function App() {
  return (
    <>
      <ToastContainer />
      <Router>
        <Suspense
          fallback={
            <Box className="splashLoader">
              <Box
                component="img"
                sx={{
                  height: 233,
                  width: 350,
                  maxHeight: { xs: 50, md: 50 },
                  maxWidth: { xs: 180, md: 180 },
                  marginBottom: 1,
                }}
                alt="brand-logo"
                src={logo}
              />
              {/* <Typography>
                FastFX
              </Typography> */}
              <ThreeCircles color={"#d42026"} height={50} />
            </Box>
          }
        >
          <ApplicationRoutes />
        </Suspense>
      </Router>
    </>
  );
}
export default App;
