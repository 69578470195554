import React, { useState } from "react";
import { createContext } from "react";

const FiltersContext = createContext({});

const FiltersContextProvider = ({ attributeItem, children }) => {
  const [categoriesInvestList, setCategoriesInvestList] = useState({
    category: [],
  });
  const [subCategoryIdsList, setSubCategoryIdsList] = useState([]);
  const [attributeValuesIdList, setAttributeValuesIdList] = useState([]);
  const [productTypeList, setProductTypeList] = useState([]);

  return (
    <FiltersContext.Provider
      value={{
        categoriesInvestList,
        setCategoriesInvestList,
        subCategoryIdsList,
        setSubCategoryIdsList,
        attributeValuesIdList,
        setAttributeValuesIdList,
        productTypeList,
        setProductTypeList,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};
export default FiltersContext;
export { FiltersContextProvider };
