import { createSlice } from "@reduxjs/toolkit";

// export const extendObject = (obj) => {
//   const clone = _.cloneDeep(obj);
//   return clone;
// };
const stepperObjInitial = {
  Nationality: "",
  Current_Address: "",
  Country_of_Residence: "",
  Country_of_Birth: "",
  radio: "",
  date: "",
  pic: "",
};

const ProductId = {
  productid: "",
};

const basicInfo = {
  first_name: "",
  last_name: "",
  email: "",
  phone_no: "",
};
const KYBCbasicInfo = {
  first_name: "",
  last_name: "",
  email: "",
  phone_no: "",
};

const KYBCpersonalInfo = {
  company_name: "",
  address: "",
  registration: "",
  employees: "",
  email: "",
  phone: "",
  url: "",
  description: "",
};
const KYBCompanyDocument = {
  pic: [],
  newPic: [],
  oldPic: [],
};
const triggerKYBAccodion = {
  triger: "",
};
const error = {
  error: "",
};

const KYBCbusinessOwner = {
  id: "",
  first_Name: "",
  last_Name: "",
  phone: "",
  email: "",
  residence: "",
  designation: "",
  license: "",
  pic: "",
  security: "",
  ownership: "",
  radio: "",
  date: "",
};

const KYBCbusinessOwner2 = {
  id: "",
  first_Name: "",
  last_Name: "",
  phone: "",
  email: "",
  residence: "",
  designation: "",
  license: "",
  pic: "",
  security: "",
  ownership: "",
};
const KYBCbusinessOwner3 = {
  first_Name: "",
  last_Name: "",
  phone: "",
  email: "",
  residence: "",
  designation: "",
  license: "",
  pic: "",
  security: "",
  ownership: "",
};
const KYBCbusinessOwner4 = {
  first_Name: "",
  last_Name: "",
  phone: "",
  email: "",
  residence: "",
  designation: "",
  license: "",
  pic: "",
  security: "",
  ownership: "",
};

const kycFaceDocumentInitial = {
  frontPic: "",
  backPic: "",
  selfiePic: "",
};

const kycStep0BasicInfo = {
  first_name: "",
  last_name: "",
  email: "",
  phone_no: "",
};

const kycStep1PersonalInfo = {
  DatePicker: "",
  Nationality: "",
  Current_Address: "",
  Country_of_Residence: "",
  Country_of_Birth: "",
};

const kycStep2ProofOfIdentity = {
  Issuing_Country: "",
  Document_Number: "",
  Age: "",
  pic: "",
  backPic: "",
  selfiePic: "",
};

const CartData = {
  orders: [""],
};

const kycStep3FinanCialInfo = {
  sourceOfFunds: "",
  averageIncome: "",
  averageInvestment: "",
};

const kycStep4RateInvestor = {
  rateInvestor: "",
};

//This is for CheckOut Form
const CheckOut = {
  firstError: "",
  lastError: "",
  countryError: "",
  streetAddressError: "",
  cityError: "",
  stateAddressError: "",
  zipCodeError: "",
  phoneError: "",
  emailError: "",
  region: "",
  description: "",
  state: "",
  company: "",
  wallet_address: ""
};

const Stripe = {
  id: "",
  amount: "",
};

const comparisonItems = {
  products: [],
};

const formAdminApproval = {
  admin: 0,
  kyb_individual_id: "",
  kyb_company_id: "",
};

const kycFormAdminApproval = {
  admin: 0,
  kyc_id: "",
};

const kybApproved = {
  indiviualApproval: false,
  companyApproval: false,
};

const kybcompanyApproved = "";
const kybIndivisualApproved = "";

const approved = "";

const initialState = {
  isAuth: null,
  isShipping : null,
  isAuthenticated: false,
  stepperForm: stepperObjInitial,
  basic: basicInfo,
  KYBCbasic: KYBCbasicInfo,
  KYBCpersonalInfo: KYBCpersonalInfo,
  KYBCompanyDocument: KYBCompanyDocument,
  triggerKYBAccodion: triggerKYBAccodion,
  error: error,
  KYBCbusinessOwner: KYBCbusinessOwner,
  KYBCbusinessOwner2: KYBCbusinessOwner2,
  KYBCbusinessOwner3: KYBCbusinessOwner3,
  KYBCbusinessOwner4: KYBCbusinessOwner4,
  kycBasicInfo: kycStep0BasicInfo,
  kycPersonalInfo: kycStep1PersonalInfo,
  kycProofOfIdentity: kycStep2ProofOfIdentity,
  kycFinanCialInfo: kycStep3FinanCialInfo,
  kycRateInvestor: kycStep4RateInvestor,
  ProductId: ProductId,
  CheckOut: CheckOut,
  Stripe: Stripe,
  CartData: CartData,
  comparisonProducts: comparisonItems,
  formAdminApproval: formAdminApproval,
  kycFormAdminApproval: kycFormAdminApproval,
  kybApproved: kybApproved,
  approved: approved,
  kybcompanyApproved: kybcompanyApproved,
  kybIndivisualApproved: kybIndivisualApproved,
  kycFaceDocument: kycFaceDocumentInitial,
  noPageLoading: false,
};

const offChainSlice = createSlice({
  name: "offChain",
  initialState,
  reducers: {
    setIsAuth: (state, action) => {
      state.isAuth = action.payload;
    },
    setAuthShipping: (state, action) => {
      state.isShipping = action.payload;
    },
    setIsStatus: (state, action) => {
      state.approved = action.payload;
    },
    setIskybcompanyApproved: (state, action) => {
      state.kybcompanyApproved = action.payload;
    },
    setIskybIndivisualApproved: (state, action) => {
      state.kybIndivisualApproved = action.payload;
    },
    setIsCart: (state, action) => {
      state.CartData = action.payload;
    },
    setIsStripe: (state, action) => {
      state.Stripe = action.payload;
    },
    setComparisonProducts: (state, action) => {
      state.comparisonProducts = action.payload;
    },
    setIsProductId: (state, action) => {
      state.ProductId = action.payload;
    },
    setIsCheckOut: (state, action) => {
      state.CheckOut = action.payload;
    },
    setisAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setStepperForm: (state, action) => {
      state.stepperForm = action.payload;
    },
    setBasic: (state, action) => {
      state.basic = action.payload;
    },
    setKYBBasic: (state, action) => {
      state.KYBCbasic = action.payload;
    },
    setKYBpersonalInfo: (state, action) => {
      state.KYBCpersonalInfo = action.payload;
    },
    setKYBCompanyDocument: (state, action) => {
      state.KYBCompanyDocument = action.payload;
    },
    setKYBAccorTriger: (state, action) => {
      state.triggerKYBAccodion = action.payload;
    },
    seterror: (state, action) => {
      state.error = action.payload;
    },
    setKYBCbusinessOwner: (state, action) => {
      state.KYBCbusinessOwner = action.payload;
    },
    setKYBCbusinessOwner2: (state, action) => {
      state.KYBCbusinessOwner2 = action.payload;
    },
    setKYBCbusinessOwner3: (state, action) => {
      state.KYBCbusinessOwner3 = action.payload;
    },
    setKYBCbusinessOwner4: (state, action) => {
      state.KYBCbusinessOwner4 = action.payload;
    },
    setKycBasicInfo: (state, action) => {
      state.kycBasicInfo = action.payload;
    },
    setKycPersonalInfo: (state, action) => {
      state.kycPersonalInfo = action.payload;
    },
    setKycProofOfIdentity: (state, action) => {
      state.kycProofOfIdentity = action.payload;
    },
    setKycFinanCialInfo: (state, action) => {
      state.kycFinanCialInfo = action.payload;
    },
    setKycRateInvestor: (state, action) => {
      state.kycRateInvestor = action.payload;
    },
    setFormApproval: (state, action) => {
      state.formAdminApproval = action.payload;
    },
    setKycFormApproval: (state, action) => {
      state.kycFormAdminApproval = action.payload;
    },
    setKybApproval: (state, action) => {
      state.kybApproved = action.payload;
    },
    setKycFaceDocument: (state, action) => {
      state.kycFaceDocument = action.payload;
    },
    setNoPageLoading: (state, action) => {
      state.noPageLoading = action.payload;
    },
  },
});

// export actions
export const {
  setIsAuth,
  setAuthShipping,
  setisAuthenticated,
  setStepperForm,
  setBasic,
  setKycBasicInfo,
  setKycPersonalInfo,
  setKycProofOfIdentity,
  setKycFinanCialInfo,
  setKycRateInvestor,
  setKYBBasic,
  setKYBpersonalInfo,
  setKYBCompanyDocument,
  setKYBAccorTriger,
  seterror,
  setKYBCbusinessOwner,
  setKYBCbusinessOwner2,
  setKYBCbusinessOwner3,
  setKYBCbusinessOwner4,
  setIsProductId,
  setIsCheckOut,
  setIsStripe,
  setIsCart,
  setComparisonProducts,
  setFormApproval,
  setKycFormApproval,
  setKybApproval,
  setIsStatus,
  setIskybcompanyApproved,
  setIskybIndivisualApproved,
  setKycFaceDocument,
  setNoPageLoading,
} = offChainSlice.actions;
// export reducer
export default offChainSlice.reducer;

// export selectors

export const isAuthSelector = (state) => state.offChain.isAuth;
export const isShippingSelector = (state) => state.offChain.isShipping;
export const isStatusSelector = (state) => state.offChain.approved;
export const iskybIndivisualApprovedSelector = (state) =>
  state.offChain.kybIndivisualApproved;
export const iskybcompanyApprovedSelector = (state) =>
  state.offChain.kybcompanyApproved;

export const isCartSelector = (state) => state.offChain.CartData;
export const isKYBCbusinessOwnerSelector = (state) =>
  state.offChain.KYBCbusinessOwner;
export const isProductId = (state) => state.offChain.ProductId;
export const isCheckOut = (state) => state.offChain.CheckOut;
export const isStripe = (state) => state.offChain.Stripe;
export const isKYBCbusinessOwnerSelector2 = (state) =>
  state.offChain.KYBCbusinessOwner2;
export const isKYBCbusinessOwnerSelector3 = (state) =>
  state.offChain.KYBCbusinessOwner3;
export const isKYBCbusinessOwnerSelector4 = (state) =>
  state.offChain.KYBCbusinessOwner4;
export const isErrorSelector = (state) => state.offChain.error;
export const isKYBCbasicSelector = (state) => state.offChain.KYBCbasic;
export const isStepperFormSelector = (state) => state.offChain.stepperForm;
export const isBasicSelector = (state) => state.offChain.basic;
export const isKYBAccorTrigerSelector = (state) =>
  state.offChain.triggerKYBAccodion;
export const isKYBCompanyDocumentSelector = (state) =>
  state.offChain.KYBCompanyDocument;
export const isKYBpersonalInfoSelector = (state) =>
  state.offChain.KYBCpersonalInfo;
export const isAuthenticatedSelector = (state) =>
  state.offChain.isAuthenticated;
export const isKYCBasicInfoSelector = (state) => state.offChain.kycBasicInfo;
export const isKYCPersonalInfoSelector = (state) =>
  state.offChain.kycPersonalInfo;
export const isKYCProofOfIdentitySelector = (state) =>
  state.offChain.kycProofOfIdentity;
export const isKYCFinanCialInfoSelector = (state) =>
  state.offChain.kycFinanCialInfo;
export const isKYCRateInvestorSelector = (state) =>
  state.offChain.kycRateInvestor;
export const isComparisonProductsSelector = (state) =>
  state.offChain.comparisonProducts;
export const isFormApprovalSelector = (state) =>
  state.offChain.formAdminApproval;
export const isKycFormApprovalSelector = (state) =>
  state.offChain.kycFormAdminApproval;
export const isKybApproved = (state) => state.offChain.kybApproved;
export const isKycFaceDocumentSelector = (state) =>
  state?.offChain?.kycFaceDocument;
export const isNoPageLoadingSelector = (state) =>
  state?.offChain?.noPageLoading;
