import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import store from "./redux/store/configureStore";
import { ProductContextProvider } from "./Context/ProductContext";
import { FiltersContextProvider } from "./Context/FiltersContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Box, Typography } from "@mui/material";
import { ThreeCircles } from "react-loader-spinner";
import logo from "./public/loginSignupAssets/Logofastfx.png";

const root = ReactDOM.createRoot(document.getElementById("root"));
const persistor = persistStore(store);
root.render(
  <PersistGate
    loading={
      <Box className="splashLoader">
        <Box
          component="img"
          sx={{
            height: 233,
            width: 350,
            maxHeight: { xs: 50, md: 50 },
            maxWidth: { xs: 180, md: 180 },
            marginBottom: 1,
          }}
          alt="brand-logo"
          src={logo}
        />
        {/* <Typography>
        FastFX
        </Typography> */}
        <ThreeCircles color={"#d42026"} height={50} />
      </Box>
    }
    persistor={persistor}
  >
    <Provider store={store}>
      <React.StrictMode>
        <ProductContextProvider>
          <FiltersContextProvider>
            <GoogleOAuthProvider clientId="347911959819-bemm3ob23k9fuq09v1imalg8uvfatvkg.apps.googleusercontent.com">
              <App />
            </GoogleOAuthProvider>
          </FiltersContextProvider>
        </ProductContextProvider>
      </React.StrictMode>
    </Provider>
  </PersistGate>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
