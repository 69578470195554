/* eslint-disable */
import React, { useState } from "react";
import { createContext } from "react";
import {
  DeleteProductVideos,
  addProductVideos,
  createProduct,
} from "../services/attribute.services";
import { useDispatch } from "react-redux";
import {
  addProductImages,
  updateProductImages,
} from "../services/attribute.services";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
const ProductContext = createContext({});
const ProductContextProvider = ({ children }) => {
  const [productAttributes, setProductAttributes] = React.useState(null);
  const dispatch = useDispatch();
  const [prodImages, setprodImages] = useState({ media: [], imageProd: {} });
  const [media, setMedia] = useState([]);
  const [video, setVideo] = useState(null);
  const [oldVideo, setOldVideo] = useState(null);
  const [videoMode, setVideoMode] = useState(false);
  const [mediaAttach, setMediaAttach] = useState([]);
  const [loadings, setLoadings] = useState(true);
  const [updateProductMedia, setUpdateProductMedia] = useState({
    media: [],
    old_media: [],
  });
  const [updateProductMediaAttach, setUpdateProductMediaAttach] = useState({
    media: [],
    old_media: [],
  });
  const payload = { method: "create", quantity: 1 };
  const updatePayload = { method: "update", quantity: 1 };
  // const [images, setImages] = useState({
  //   media: [],
  //   pid: "",
  // });
  const updateProductState = (data) => {
    setProductAttributes(data);
  };
  const getProductState = () => {
    return productAttributes;
  };
  const getProductStateAttach = () => {
    return productAttributes;
  };

  const productCreation = async (callBack, warningCallback) => {
    const mustHaveAttributes = [
      "Name",
      "Description",
      "Price",
      "Units",
      "Tags",
      "ProductType",
      "ProfitFrequency",
      "Roi",
      "OrderType",
    ];
    const investmentMust = ["ProfitFrequency", "Roi", "OrderType"];
    let imageObj = "";
    let imageObjAttach = "";
    let reachLimit = productAttributes?.attribute_values?.filter((item) => {
      if (item?.is_shown === 3) {
        return item;
      }
    });
    let isRequiredList = mustHaveAttributes?.filter((item) => {
      if (
        productAttributes?.attribute_values?.find(
          (it) =>
            it?.name == item && (it?.value === null || it?.value?.length == 0)
        )
      ) {
        return item;
      }
    });
    let findProductType = productAttributes?.attribute_values?.find(
      (item) => item?.name == "ProductType"
    );

    if (findProductType?.value == "Sale") {
      isRequiredList = isRequiredList?.filter(
        (item) => !investmentMust?.includes(item)
      );
    }

    if (isRequiredList.length == 0 && media.length > 0) {
      if (reachLimit?.length >= 1 && reachLimit?.length <= 4) {
        const response = await dispatch(
          createProduct({
            ...productAttributes,
            ...payload,
          })
        );
        imageObj = response[response.length - 1];
        "response length", imageObj;
        // setprodImages({ imageProd: response[response.length - 1] });
        let formData = new FormData();
        media.map((img, index) => {
          formData.append(`media[${index}]`, img);
        });
        formData.append("uuid", imageObj?.uuid);
        formData.append("user_id", imageObj?.user_id);
        formData.append("category_id", imageObj?.category_id);
        formData.append("sub_category_id", imageObj?.sub_category_id);
        formData.append("component_id", imageObj?.component_id);
        // formData.append("media[]", media);
        for (const pair of formData.entries()) {
          `${pair[0]}, ${pair[1]}`;
        }
        // let formDataAttach = new FormData();
        // mediaAttach.map((img, index) => {
        //   formDataAttach.append(`media[${index}]`, img);
        // });
        // formDataAttach.append("uuid", imageObj?.uuid);
        // formDataAttach.append("user_id", imageObj?.user_id);
        // formDataAttach.append("category_id", imageObj?.category_id);
        // formDataAttach.append("sub_category_id", imageObj?.sub_category_id);
        // formDataAttach.append("component_id", imageObj?.component_id);
        // // formData.append("media[]", media);
        // for (const pair of formDataAttach.entries()) {
        //   (`${pair[0]}, ${pair[1]}`);
        // }
        const responseImages = await dispatch(addProductImages(formData));
        if (video !== null && video !== undefined) {
          let videoForm = new FormData();
          videoForm.append("video", video);
          videoForm.append("product_id", imageObj?.uuid);
          let videoRes = await dispatch(addProductVideos(videoForm));
          console.log("video upload -->", videoRes);
        }
        // imageProd: response[response.length - 1],
        setUpdateProductMediaAttach({ ...updateProductMediaAttach, media: [] });
        setUpdateProductMediaAttach({
          ...updateProductMediaAttach,
          old_media: [],
        });
        setUpdateProductMedia({ ...updateProductMedia, media: [] });
        setUpdateProductMedia({ ...updateProductMedia, old_media: [] });
        callBack(response);
      } else {
        toast.warning(
          "Please select maximum 4 product attributes that you want to show on your product card.",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
          setLoadings(false)
        );
      }
    } else {
      toast.warning(
        "Please fill all the compulsory Fields.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
        setLoadings(false)
      );
    }
  };

  const productUpdation = async (callBack) => {
    let imageObj = "";
    // ("product updation about to hit");
    //add method create here and call api here
    const response = await dispatch(
      createProduct({
        ...productAttributes,
        ...updatePayload,
      })
    );
    let addVideo = null;
    // "response in update", response[response.length - 1];
    imageObj = response[response.length - 1];
    // setprodImages({ imageProd: response[response.length - 1] });
    // "old images", updateProductMedia;
    let formData1 = new FormData();
    updateProductMedia?.media.map((img, index) => {
      formData1.append(`media[${index}]`, img);
    });
    updateProductMedia?.old_media.map((img, index) => {
      formData1.append(`old_media[${index}]`, img);
    });
    // formData1.append("old_media[]", updateProductMedia.old_media);
    formData1.append("uuid", imageObj?.uuid);
    formData1.append("user_id", imageObj?.user_id);
    formData1.append("category_id", imageObj?.category_id);
    formData1.append("sub_category_id", imageObj?.sub_category_id);
    formData1.append("component_id", imageObj?.component_id);
    // formData.append("media[]", media);
    for (const pair of formData1.entries()) {
      `${pair[0]}, ${pair[1]}`;
    }
    const responseImages = await dispatch(
      updateProductImages(
        formData1,
        () => {},
        () => {}
      )
    );

    if (oldVideo !== null && oldVideo !== undefined) {
      // let videoDelete = await dispatch(DeleteProductVideos(imageObj?.uuid));
      // if (videoDelete) {
      let videoForm = new FormData();
      videoForm.append("video", video);
      videoForm.append("product_id", imageObj?.uuid);
      addVideo = await dispatch(addProductVideos(videoForm));
      setVideoMode(false);
      setOldVideo(null);
      setVideo(null);
      // }
    } else if (videoMode && video) {
      let videoForm = new FormData();
      videoForm.append("video", video);
      videoForm.append("product_id", imageObj?.uuid);
      addVideo = await dispatch(addProductVideos(videoForm));
      setVideoMode(false);
      setOldVideo(null);
      setVideo(null);
    }
    setVideoMode(false);
    setOldVideo(null);
    setVideo(null);
    setUpdateProductMediaAttach({ ...updateProductMediaAttach, media: [] });
    setUpdateProductMediaAttach({ ...updateProductMediaAttach, old_media: [] });
    setUpdateProductMedia({ ...updateProductMedia, media: [] });
    setUpdateProductMedia({ ...updateProductMedia, old_media: [] });
    if (response && addVideo) {
      callBack(response);
    } else {
      callBack(response);
    }
  };

  // const setProductImages = async (uploadedImages, productObj) => {
  //   ("images array", uploadedImages);
  //   ("prod obj", prodImages);
  //   // setprodImages({ ...prodImages, media: uploadedImages });
  //   // setImages({ ...images, media: uploadedImages });
  //   const response = await dispatch(
  //     addProductImages({
  //       ...prodImages,
  //     })
  //   );
  //   ("image upload response", response);
  // };

  const updateProductAttributes = (updatedItem) => {
    // "updated item in updateProductAttributes",
    //   productAttributes,
    //   " ----> ",
    //   updatedItem;
    let copyAttributes = productAttributes;
    let copyAttributesValues = [...productAttributes?.attribute_values];
    let index = productAttributes.attribute_values.findIndex(
      (item) => item.component_id == updatedItem.component_id
    );
    if (index > -1) {
      copyAttributesValues[index] = updatedItem;
    } else {
      copyAttributesValues = [...copyAttributesValues, updatedItem];
    }
    copyAttributes.attribute_values = [...copyAttributesValues];
    setProductAttributes(copyAttributes);
  };

  const updateProductAttributesAttach = (updatedItem) => {
    "updated item in updateProductAttributes",
      productAttributes,
      " ----> ",
      updatedItem;
    let copyAttributes = productAttributes;
    let copyAttributesValues = [...productAttributes?.attribute_values];
    let index = productAttributes.attribute_values.findIndex(
      (item) => item.component_id == updatedItem.component_id
    );
    if (index > -1) {
      copyAttributesValues[index] = updatedItem;
    } else {
      copyAttributesValues = [...copyAttributesValues, updatedItem];
    }
    copyAttributes.attribute_values = [...copyAttributesValues];
    setProductAttributes(copyAttributes);
  };
  return (
    <ProductContext.Provider
      value={{
        productAttributes,
        getProductState,
        productCreation,
        productUpdation,
        setProductAttributes,
        updateProductState,
        updateProductAttributes,
        setMedia,
        setUpdateProductMedia,
        updateProductMedia,
        setLoadings,
        loadings,
        setVideo,
        video,
        oldVideo,
        setOldVideo,
        videoMode,
        setVideoMode,
        // setprodImages,
        // setProductImages,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};
export default ProductContext;
export { ProductContextProvider };
