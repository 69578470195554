import axios from "axios";
import apiObj from "../utils/api";
import { getItem } from "../utils/storage";
const api = apiObj.url;

export const fetchToken = async () => {
  const tokenContainer = await getItem("tokenContainer");
  // ('token Container', tokenContainer);
  return tokenContainer && `Bearer ${tokenContainer.token}`;
};

export const fetchFaceToken = async () => {
  const token = await getItem("fkt");
  return token && `Bearer ${token}`;
};

export const request = (
  url,
  type,
  data,
  headers,
  params,
  extraOptions = null
) =>
  new Promise(async (resolve, reject) => {
    const requestObj = {
      method: type,
      url: api + url,
      ...extraOptions,
    };
    if (headers) {
      requestObj.headers = {
        Authorization: await fetchToken(),
      };
      if (headers.contentType) {
        requestObj.headers["content-type"] = headers.contentType;
      }
    }
    type !== "get" && (requestObj.data = data);
    params && (requestObj.params = params);

    if (!requestObj.url.includes("refresh")) {
      axios(requestObj).then(resolve, reject);
    }
  });

export const facekiRequest = (
  url,
  type,
  data,
  headers,
  params,
  extraOptions = null
) =>
  new Promise(async (resolve, reject) => {
    const requestObj = {
      method: type,
      url: `https://sdk.faceki.com/${url}`,
      ...extraOptions,
    };
    if (headers) {
      requestObj.headers = {
        "content-type": "multipart/form-data",
        // "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("fkt")}`,
      };
      if (headers.contentType) {
        requestObj.headers["content-type"] = headers.contentType;
      }
    }
    type !== "get" && (requestObj.data = data);
    params && (requestObj.params = params);

    if (!requestObj.url.includes("refresh")) {
      axios(requestObj).then(resolve, reject);
    }
  });

export const referralRequest = (
  url,
  type,
  data,
  headers,
  params,
  extraOptions = null
) =>
  new Promise(async (resolve, reject) => {
    const requestObj = {
      method: type,
      url: api + url,
      ...extraOptions,
    };
    if (headers) {
      requestObj.headers = {
        Authorization: localStorage.getItem("pkt"),
      };
      if (headers.contentType) {
        requestObj.headers["content-type"] = headers.contentType;
      }
    }
    type !== "get" && (requestObj.data = data);
    params && (requestObj.params = params);

    if (!requestObj.url.includes("refresh")) {
      axios(requestObj).then(resolve, reject);
    }
  });
